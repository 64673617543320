.user-profile {
  display: block;

  &.visible {
    display: flex;
  }

  .RightMenu-Box {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    z-index: 10001;
    overflow-y: auto;

    .Box-Content {
      &::-webkit-scrollbar {
        width: 8px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        outline: none;
        border-radius: 0;
      }
      &::-webkit-scrollbar-track {
        background-color: #ddd;
      }
    }
  }

  .Box-Head-Close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;

    &:hover i {
      color: var(--color-dark-green);
    }

    i {
      color: white;
    }
  }

  .Box-Head {
    width: 100%;
    min-height: 60px;
    align-items: center;
    display: flex;
    padding: 10px 20px;
    box-sizing: border-box;
    background: var(--color-green);
  }

  .RightMenu-avatar-block {
    color: var(--color-white);
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    display: flex;

    .TitleSubtitle {
      text-align: center;
      margin: 5px 0 10px 0;
    }
  }

  .TitleSubtitle-subtitle__mail {
    margin-top: 5px;
  }

  .Avatar.rounded,
  .Avatar.rounded img {
    border-radius: 6px;
  }

  .Avatar.rounded {
    overflow: hidden;
  }

  .Avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;

    img {
      height: auto;
      width: 100%;
    }
  }

  .RightMenu-actions {
    margin: 5px 0 10px 0px;
    display: flex;
    font-size: 14px;
    font-family: var(--font-roboto-medium);

    button {
      min-width: 120px;
    }
  }

  .RightMenu-actions--edit.Button {
    background-color: var(--color-dark-green);
    color: var(--color-green);
    height: 36px;
    border: none;
    font-weight: 400;
    font-family: var(--font-family-base);
    border-radius: 6px;

    &:not(:disabled):hover {
      color: var(--color-white);
    }
  }

  .RightMenu-actions--logout.Button {
    border: 1px solid var(--color-dark-green);
    color: var(--color-dark-green);
    height: 36px;

    &:not(:disabled):hover {
      background-color: var(--color-dark-green);
      color: var(--color-white);
    }
  }

  .Box-Content {
    width: 100%;
    flex: 1;
    max-height: calc(100vh - 190px);
    overflow-y: auto;
  }

  .RightMenu-title {
    background: #efefef;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 700;
    color: #949494;
    padding: 10px 20px 10px 10px;
    font-family: var(--font-roboto-bold);
    line-height: 1.3;
  }

  .Box-List-Item {
    display: flex;
    align-items: center;
    position: relative;
    padding: 10px 20px 10px 10px;

    &.active {
      background: #d0d0d0;
      pointer-events: none;
    }
  }

  .RightMenu-account .Box-List-Item {
    border-bottom: 1px solid #efefef;
  }

  .Box-List-Item .TitleSubtitle {
    flex: 1;
    padding: 0 10px;
  }

  .Box-List-Item .TitleSubtitle,
  .Box-List-Item label {
    flex: 1;
  }

  .Box-List-Item:not(:disabled):hover {
    cursor: pointer;
    background: #d0d0d0;
  }

  .RightMenu-Block {
    background-color: var(--color-white);
    border-radius: 4px;
    box-sizing: border-box;
  }

  .RightMenu-shipment {
    font-family: var(--font-roboto-medium);
    font-size: 14px;
    color: var(--color-gray);

    & > span {
      margin-right: 10px;
      font-size: 11px;
      font-family: var(--font-roboto-bold);
    }
  }

  .RightMenu-booking--actions {
    display: flex;
    margin-top: 18px;

    button {
      width: 150px;
      height: 36px;
      border-radius: 4px;
      background-color: var(--color-dark-green);

      &:last-child {
        margin-left: 10px;
      }

      &:not(:disabled):hover {
        background-color: var(--color-green);
      }
    }
  }

  .RightMenu-referral {
    display: flex;
    .RightMenu-referral--code {
      flex: 1;
      padding-right: 10px;
    }
  }

  #share-eta-content {
    > div {
      padding-right: 90px;
    }
  }

  .Overlay-Checkbox {
    width: 20px;

    input {
      opacity: 0;
      position: absolute;
      left: 0;
      top: 0;
      width: 100% !important;
      height: 100% !important;
      margin: 0;
      z-index: 2;
      cursor: pointer;
    }

    input[type="checkbox"]:checked + .Checkbox:before {
      background-color: var(--color-dark-green);
      border-color: var(--color-dark-green);
    }
  }

  .RightMenu__show-phone {
    justify-content: space-between;
  }

  .show-phone__text {
    flex: 1;
    span {
      margin-left: 10px;
      i {
        position: relative;
        top: 4px;
        color: #7c7c7c;
        font-size: 21px;
      }
      &:hover {
        i {
          color: var(--color-green);
        }
      }
    }
  }

  .popup__mask-phone {
    max-width: 330px !important;
    .modal-content {
      .modal-header {
        padding-bottom: 0;
      }
      .modal-body {
        padding: 0 15px 15px;
        font-size: 15px;
        line-height: 24px;
        text-align: center;
      }
    }
  }

  .Avatar__header.rounded,
  .Avatar__header.rounded img {
    border-radius: var(--radius);
  }

  .Avatar__header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 46px;
    height: 46px;
  }

  .Avatar__header.rounded {
    overflow: hidden;
  }

  .Avatar__header img {
    height: auto;
    width: 100%;
  }
}
