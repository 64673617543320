.Vehicle-Service-Type-Popup-Eye {
  .Logo {
    height: 40px;
    width: 50px;
    img {
      width: 100% !important;
      height: 100% !important;
      object-fit: contain;
      object-position: center;
    }
  }
}