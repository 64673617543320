.BatchUploadLayout {
  .PageHead {
    .cashback-bar-wrapper {
      margin: 0;
    }

    .cashback-item {
      justify-content: end;
      margin-right: 10px;
    }

    &.step-5 .cashback-item {
      margin-right: 52px;
    }
  }
}