.invalid-payment-popup {
  .container {
    width: 725px;
    background-color: #51af2b;
    border-radius: 8px;
  }
  .header {
    height: 60px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    font-size: 17px;
    font-weight: bold;
    color: #ffffff;
  }
  .content {
    padding: 10px;
    padding-top: 0;
    .padding-style {
      font-size: 18px;
      background: #ffffff;
      border-radius: 4px;
      color: #444;
      text-align: center;
      position: relative;
      padding: 0 130px;
      .text {
        padding-top: 30px;
        padding-bottom: 25px;
      }
      .text-under {
        padding: 0 10px;
        padding-bottom: 30px;
      }
      .action-button {
        padding-bottom: 20px;
        display: flex;
        justify-content: center;
        gap: 20px;
        button {
          width: 180px;
          height: 48px;
        }
        .back-edit-btn {
          border-radius: 4px;
          border: solid 1px #a1a1a1;
          background-color: #ffffff;
          font-size: 20px;
          font-weight: 500;
          color: #282828;
        }
        .main-btn {
          font-size: 20px;
          border-radius: 4px;
          outline: none;
          box-shadow: none;
          border: none;
          background: #40ae29;
          color: white;
          font-weight: bold;
          &:hover {
            background: #0e730f;
          }
        }
      }
    }
  }
}