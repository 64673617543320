//= require_self

//= require animsition
//= require semantic-ui/dist/semantic
//= require react-rater
//= require alertify/themes/alertify.default
//= require alertify/themes/alertify.core
//= require eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker

body.webapp{
  /* Overwrite the default to keep the scrollbar always visible */
  ::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
  }

  ::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0,0,0,.5);
      -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
  }

  .header-buttons {
    .ui.button {
      background: none;
      color: $colorGreen;
      font-weight: 300;
      img { height: 20px; }
      &:hover, &:focus {
        color: $colorGreenDark;
        text-decoration: underline;
        box-shadow: none !important;
      }
    }
    .text {
      font-size: 25px;
      font-weight: 300;
      background: none;
      color: $colorGreen;
      padding: 10px;
    }
  }
  a[href^="tel:"] { &:hover, &:focus { text-decoration: none; } }
  // new semantic classes
  .ui.tiny.images .image, .ui.tiny.images img, .ui.tiny.images svg, .ui.tiny.image{
    width: 65px;
    &.square { height: 65px; }
    height: auto;
    font-size: 0.85714286rem;
  }
  .app-icon img{ width: 120px !important; }
  .main-list {
    overflow-y: hidden; overflow-x: auto; text-align: center; margin-bottom: 0px !important;
    &.pdt-0 { padding-top: 0px !important; }
  }
  @include utility-responsive(768px) {
    .header-buttons {
      position: absolute;
      top: 10px;
      right: 0px;
    }
  }
  #main-container{
    .scrollable { overflow-y: auto; }
    // responsive
    @include utility-responsive-range(0px, 1200px) {
      #bottom-panel {
        .all-info {
          .driver-info.just-full {
            .column { width: 100% !important;
              > .ui.list { border: none; }
              &.aligned { text-align: left; }
            }
          }
        }
      }
    }
    @include utility-responsive-range(0px, 768px) {
      #main-map{ min-height: 600px; }
    }
    @include utility-responsive(768px) {padding-left: 0px;
      #new-booking-column { width: 400px !important; }
      #new-booking-map {
        display: inline-block;
        width: calc(100% - 400px) !important;
      }
      &:not(.sixteen) .yield{ margin-left: -1rem; }
      #welcome-panel{ position: absolute; z-index: 1;
        > .segment { margin: 1em 0px 0px 1em; }
      }
      #main-map{ margin-left: 0px; }
      .marged-box { margin: 1rem 2rem 0px; }
      // booking tracking
      #bottom-panel{
        position: absolute; bottom: 0; left: 0; z-index: 1;
        // .all-info { min-width: 650px; }
        #cancel-button { top: -15px; }
        #review-details-button { top: -15px; }
        #driver-info-modal .tracking-modal { max-width: 450px; }
        #cancel-booking-modal .tracking-modal { max-width: 450px; }
        #retry-modal .tracking-modal { max-width: 450px; }
        #review-modal .tracking-modal { max-width: 450px; }
      }
      // booking review
      .booking-review {
        .driver-infos-block {
          position: fixed;
          bottom: 0px;
          width: 90%;
          padding: 0px 0px 10px;
          .vehicle-infos { margin-top: 15px }
          .ui.button { margin-top: 12px }
          .three.wide.column { min-width: 220px;
            &:last-child { text-align: center; }
          }
        }
        table.fee-table { margin-top: 60px; }
        .pickup-time-block { margin-top: 0px; }
      }
      // new booking sign-in modal
      #new-session-modals .modal { max-width: 400px; }
    }
  }
  .new-booking{
    margin-left: 0rem!important;
    .four{
      background: #f5f5f5;
      box-shadow: 2px 0px 8px -2px #888888;
      z-index: 1;
      #step-header-buttons{
        background: white!important;
      }
    }
    .twelve{
      padding-left: 0px!important;
      padding-top: 1rem!important;
    }
  }
  .booking-tracking{
    margin-left: -1rem!important;
  }
  .form-content{
    background: #f5f5f5!important;
    .active-radio{
      font-weight: bold!important;
    }
    .disable-radio{
      color: #9E9999  !important;
      font-weight: 700;
    }
    .locations {
      background: white;
      margin: -8px -20px 10px!important;
      .header {
        font-weight: bold!important;
        padding-top: 10px;
      }
    }
    .service-types, .vehicle-types, .pickup-time{
      background: white;
      margin: -4px -15px 10px;
      padding: 0px 12px;
      &.last {padding-bottom: 10px;}
      .header {
        font-weight: bold!important;
        padding-top: 10px;
      }
    }

    .discount-code, .note, .job-order-number, .extra-requirements{
      background: white;
      margin: -4px -15px 10px;
      padding: 0 0 10px 12px;
      .header {
        font-weight: bold!important;
        padding-top: 10px;
      }
    }
    .discount-code  #discount-code{
      width: 97%!important;
    }
    .note #note{
      width: 97%!important;
    }
    .job-order-number #job-order-number{
      width: 97%!important;
    }

    .extra-requirements{
      .square {
        margin-right: 10px!important;
      }
      .selection{
        margin-right: 10px!important;
      }
    }

    .extra-requirements-negative-position{
      .square {
        margin-right: 0px!important;
      }
      .selection{
        // margin-right: 10px!important;
      }
    }
    // .simple-info{
    //   background: white;
    //   margin: -8px -15px 0px;
    //   padding: 7px 0 10px 12px;
    // }
    .pictures{
      background: white;
      margin: -4px -15px 10px;
      padding: 0 0 10px 12px;
      .header {
        font-weight: bold!important;
        padding-top: 10px;
      }
    }
    .other-info{
      background: white;
      margin: -4px -15px 10px;
      padding-left: 12px;
      .header {
        font-weight: bold!important;
        padding-top: 10px;
      }
    }
    .fee-details{
      background: white!important;
      margin: -8px -15px 10px;
      padding-left: 12px;
      .header {
        font-weight: bold!important;
        padding-top: 10px;
      }
      .list{
        background: white!important;
      }
    }
    .location-details{
      background: white!important;
      margin: 0px -15px 10px;
      padding-left: 12px;
    }
    .pickup-time-info-big{
      background: white!important;
      padding-top: 10px;
      margin: -4px -15px 10px;
      margin-bottom: 0px!important;
      .type{
        padding-left: 25px!important;
        width: inherit;
      }
    }
    .list-location{
      .description-location{
        height: 32px;
        width: 81%;
        background: #f5f5f5;
        margin: 0px 0 10px 55px;
        color: #b9b9b9;
        border: none;
        border-radius: 0px;
      }
    }
  }
  .horizontal-line{
    margin: 10px -15px 10px;
    border-bottom: 1px solid #f5f5f5;
  }
  .horizontal-mid-line{
    width: 81%;
    margin: -4px 0 10px 55px;
    padding: 5px 0 0 15px;
    border-bottom: 1px solid #f5f5f5;
  }

  .action-location{
    list-style-type: none;
    display: inline-flex;
    margin-right: 5px;
    padding-left: 0px;
    cursor: pointer;
    &:empty { min-width: 20px; }
  }
  #step-header-buttons{
    &.ui.buttons{
      margin: 0em 0.25em 0em -1em!important;
      width: 107%!important;
    }
    .button{
      padding-top: 15px!important;
      cursor: default;
    }
    .button.active {
      height: 46px;
      // background: #38a322!important;
    }
    .button.active:not(:last-child):after {
      border-top: 22px solid transparent!important;
      border-bottom: 24px solid transparent!important;
      // border-left: 18px solid #38a322!important;
    }
    .button.active:not(:last-child):before {
      border-top: 22px solid transparent!important;
      border-bottom: 24px solid transparent!important;
      border-left: 18px solid white!important;
    }
    .button.active:not(:first-child):before {
      border-top: 22px solid transparent!important;
      border-bottom: 24px solid transparent!important;
      border-left: 18px solid white!important;
    }
  }
  #first-time-discount {
    display: none;
    position: absolute;
    margin: auto;
    top: 32%;
    left: 38%;
    // background-color: #66CCFF;
    // color: #fff;
    // position: absolute;
    // width: 65%;
    // height: auto;
    // z-index: 9999;
    // bottom: 20px;
    // left: 0;
    // right: 0;
    // margin: auto;
    // padding: 10px;
    // @include utility-responsive(320px) {
    //   width: 65%;
    // }
    // @include utility-responsive(480px) {
    //   width: 75%;
    // }
    // @include utility-responsive(600px) {
    //   width: 460px;
    // }
    // @include utility-responsive(768px) {
    //   width: 65%;
    // }
    // @include utility-responsive(1024px) {
    //   width: 460px;
    // }
    .discount_message{
      background-color: #00bbe6;
      border-radius: 50%;
      padding: 10px;
      border: 10px solid white;
      display: inline-block;
      position: absolute;
      -webkit-box-shadow: -1px 0px 21px 3px rgba(133,129,133,0.69);
      -moz-box-shadow: -1px 0px 21px 3px rgba(133,129,133,0.69);
      box-shadow: -1px 0px 21px 3px rgba(133,129,133,0.69);
      .content{
        border-radius: 50%;
        border: 2px dashed white;
        display: inline-block;
        padding: 20px;
      }
      .text{
        width: 146px;
        height: auto;
        margin: 15px 0 0 20px;
        text-align: center;
        color: white;
        font-weight: 700;
        position: absolute;
        top: -16px;
        left: -6px;
        p{
          line-height: 30px;
        }
      }
      .text_en{
        font-size: 25px;
      }
      .text_other{
        font-size: 18px;
        span sup {
          top: 25px!important;
        }
      }
      .text sup{
        font-size: 14px;
        padding-right: 4px;
      }
      .text span{
        color: yellow;
        font-size: 3.5em !important;
        margin-top: -45px;
        position: relative;
        sup{
          font-size: 25px;
          position: absolute;
          top: 32px;
          // right: -18px;
        }
      }
      .row_message {
        display: table;
        width: 172px;
        padding-bottom: 100%;
        position: relative;
      }
      .item {
        display: table-cell;
      }
      .item .text{
        background-color: #028cab;
        width: 260px;
        height: auto;
        min-height: 24px;
        padding: 10px;
      }
      .content_footer{
        position: absolute;
        top: 187px;
        color: white;
        font-weight: 700;
        display: inline-block;
        left: -16px;
      }
      .content_footer div{
        display: block;
        float: left;
      }
      .content_footer .left{
        transform: rotate(180deg) scaleY(-1);
      }
      .content_footer .mid{
        background-color: #028cab;
        height: auto;
        width: 214px;
        padding: 10px;
        margin-top: -16px;
        z-index: 34;
        position: relative;
        margin-left: -15px;
        text-align: center;
      }
      .content_footer .right img{
        margin-left: -14px;
        z-index: 1;
      }
      .content_footer .left img{
        margin-left: 0px;
        z-index: 1;
      }
      .content_footer .right{
        position: absolute;
        left: 240px;
      }
    }
  }
  .rating-block {
    .icon {
      font-size: 16px;
      height: 16px;
      width: 16px;
    }
    &.yellow {
      .icon {
        color: #f5e100;
        &:not(.half).empty {
          color: #e9e9e9;
        }
      }
    }
  }
  .ui.dimmer {
    background: rgba(108, 108, 108, 0.8);
  }
  .show-full {
    .bg-full {
      width: 100%;
      height: 180px;
      background-size: cover;
      background-position: center;
    }
  }
  // Set max-width of freshdesk chat box
  #lc_chat_layout {
    max-width: 240px;
  }
}