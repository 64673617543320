.form.my-style {
  .ui.selection.dropdown {
    border: none;
    border-bottom: 1px solid $colorGrayLight;
    -webkit-box-shadow: none;
       -moz-box-shadow: none;
            box-shadow: none;
    @include custom-rounded(0px);
    &:focus {
      border-bottom: 1px solid $colorGreen;
      -webkit-box-shadow: none;
         -moz-box-shadow: none;
              box-shadow: none;
      @include custom-rounded(0px);
    }
    .dropdown.icon { color: $colorGreen; }
    .menu.transition { @include custom-rounded(0px); }
  }
  .custom-date-range {
    padding: 5px 10px;
    background: $colorGrayLight;
    display: inline-block;
  }
  .circle-radio.inline {
    cursor: pointer;
    margin-top: 5px;
    i.flag { vertical-align: middle; }
  }
  .ui.input {
    .icon.calendar,
    .icon.search { color: $colorGreen; }
  }
}