$smallSize: 36px;
$largeSize: 52px;
$transformStyle: transform 0.5s cubic-bezier(.34, .68, .57, .87);
$delayTime: 2s;

.cashback-bar-wrapper {
  overflow: hidden;
  &.is-show {
    overflow: unset;
    z-index: 10002;
  }

  .tally-bar-container {
    margin: 0;
  }
}

.SingleBookingLayout {
  .cashback-bar-inner {
    padding: 0 5px;
    margin: 0 5px;
    .items-wrapper {
      margin-top: 15px;
    }
  }
}

.cashback-bar-inner {
  transition: $transformStyle;
  transform: translateX(0);

  .items-wrapper {
    background-color: #f1f1f1;
    border-radius: 5px;
    height: $smallSize;
    overflow: hidden;
    &__two-line {
      height: $largeSize;
      padding-top: 0!important;
      .cashback-item {
        height: $largeSize !important;
        padding: 0 10px !important;
      }
    }
  }

  .hide-cashback-bar {
    height: 0!important;
    margin: 0!important;
  }

  .cashback-bar {
    flex: 1;
    .slide-up {
      transform: translateY(-$smallSize);
      transition: $transformStyle;
      transition-delay: $delayTime;
    }
    .slide-up-two-line {
      transform: translateY(-$largeSize);
      transition: $transformStyle;
      transition-delay: $delayTime;
    }
    .cashback-item {
      height: $smallSize;
      display: flex;
      align-items: center;
      padding: 0 10px;
    
      p {
        margin: 0;
        font-size: 14px;
        color: #282828;
        letter-spacing: -0.001px;
        font-weight: 400;
        line-height: normal;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2; 
        -webkit-box-orient: vertical;
      }
    
      img {
        margin-right: 6px;
      }
    }
  }

  .tally-bar {
    margin-top: 10px;
  }

  &.show-tally {
    transform: translateX(-50%);
  }
}

.fpr-text {
  display: flex;
  padding: 10px;
  align-items: center;
  gap: 5px;
  border-radius: 5px;
  background: #EBF6E9;
  margin-top: 15px;
  img {
    padding-right: 5px;
    height: 16px;
    width: 16px;
  }
  p {
    margin: 0px;
    margin-top: -1px;
    color: #282828;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
