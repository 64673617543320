.route-detail {
  position: relative;
  cursor: pointer;
  font-size: 12px;
  text-decoration: underline;
  color: #0e730f;
  padding-left: 28px;

  &::before {
    content: '';
    display: block;
    width: 9px;
    height: 9px;
    border: solid 1px #f79f1f;
    background-color: #ffdb00;
    border-radius: 50%;
    position: absolute;
    bottom: 25%;
    left: 10px;
  }
}

.route-detail-popup {
  position: fixed;
  background: var(--color-white);
  z-index: 3;
  border-radius: var(--radius);
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.4);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;

  .block-item {
    position: relative;
  }

  .block-item-custom {
    min-height: 28px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    &::before {
      content: '';
      background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNHB4IiBoZWlnaHQ9IjJweCIgdmlld0JveD0iMCAwIDQgMiIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNDkuMSAoNTExNDcpIC0gaHR0cDovL3d3dy5ib2hlbWlhbmNvZGluZy5jb20vc2tldGNoIC0tPgogICAgPHRpdGxlPkxpbmUgQ29weSA4PC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGRlZnM+PC9kZWZzPgogICAgPGcgaWQ9IkNvc21ldGljIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2UtZGFzaGFycmF5PSIyLDQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+CiAgICAgICAgPGcgaWQ9IlN0ZXAtNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTExOS4wMDAwMDAsIC02OTAuMDAwMDAwKSIgc3Ryb2tlPSIjN0M3QzdDIj4KICAgICAgICAgICAgPGcgaWQ9Ikdyb3VwLTUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDE3LjAwMDAwMCwgMTk1LjAwMDAwMCkiPgogICAgICAgICAgICAgICAgPGcgaWQ9IkxpbmUtRG90IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg3OC4wMDAwMDAsIDQ3NC4wMDAwMDApIj4KICAgICAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMjUuMTk1MzgzNCwyMS43NSBMMzAuOTE1MzgzMiwyMS43NSIgaWQ9IkxpbmUtQ29weS04Ij48L3BhdGg+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==');
      position: absolute;
      bottom: 5px;
      left: 0;
      right: 0;
      z-index: -1;
      background-repeat: repeat-x;
      height: 2px;
      background-size: 5px;
      background-position: 0px 0;
    }

    label, span {
      background-color: var(--color-white);
    }
  }

  .Pricing-LongHaul.New-Pricing-LongHaul {
    border: none;
    margin: 0;
    padding: 15px;
    max-height: 55vh;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 4px;
      -webkit-appearance: none;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--color-dark-green);
      outline: none;
      border-radius: 0;
    }

    &::-webkit-scrollbar-track {
      background-color: #d7edd7;
    }
  }
}

.BatchUploadValidate .route-detail-popup {
  top: 30%;
  left: auto;
  right: 32px;
  width: 312px;
  transform: none;
  z-index: 23;
}