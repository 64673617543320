.cashback-explain {
  &.popup-wrapper {
    position: absolute;
    z-index: 10002;

    .popup-inner {
      width: 375px;
    }

    .btn-wrapper .button {
      flex: 1;
      background-color: var(--color-white);
      color: var(--color-green);
      font-size: 18px;
    }

    .content-wrapper {
      .title {
        font-size: 22px;
      }

      .content {
        font-size: 18px;
      }

      .icon img {
        width: 60%;
      }
    }
  }
}