.intl-tel-input {
  position: relative;
  display: flex;
  flex: 1;
  font-size: 15px;
  height: 36px;
}

.intl-tel-input * {
  box-sizing: border-box;
  -moz-box-sizing: border-box
}

.intl-tel-input .hide {
  display: none
}

.intl-tel-input .v-hide {
  visibility: hidden
}

.intl-tel-input input,
.intl-tel-input input[type=tel],
.intl-tel-input input[type=text] {
  position: relative;
  z-index: 0;
  margin-top: 0!important;
  margin-bottom: 0!important;
  padding-right: 36px;
  margin-right: 0;
  padding-left: 10px !important;
  padding-right: 10px !important;
  box-sizing: border-box;
  width: 100%;
}

.intl-tel-input .flag-container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  padding: 1px
}

.intl-tel-input .selected-flag {
  z-index: 1;
  position: relative;
  height: 100%;
  padding: 0 0 0 8px;
  width: auto !important;
  display: flex !important;
  align-items: center;
  padding-left: 38px;
}

.intl-tel-input .selected-flag .iti-flag {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  display: none;
}

.intl-tel-input .selected-flag .iti-arrow {
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 4px solid #555;
  position: unset;
  top: 0;
  right: 0;
  margin: 0 10px 0 6px;
}

.intl-tel-input .selected-flag .iti-arrow.up {
  border-top: none;
  border-bottom: 4px solid #555
}

.intl-tel-input .selected-flag .arrow {
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 4px solid #555;
  position: unset;
  margin: 0 10px 0 6px;
}

.intl-tel-input .selected-flag .arrow.up {
  border-top: none;
  border-bottom: 4px solid #555
}

.intl-tel-input .country-list {
  position: absolute;
  z-index: 2;
  list-style: none;
  text-align: left;
  padding: 0;
  margin: 0 0 0 -1px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, .2);
  background-color: #fff;
  border: 1px solid #ccc;
  white-space: nowrap;
  max-height: 200px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch
}

.intl-tel-input .country-list.dropup {
  bottom: 100%;
  margin-bottom: -1px
}

.intl-tel-input .country-list .flag-box {
  display: inline-block;
  width: 34px;
  margin-right: 10px;
}

@media (max-width:500px) {
  .intl-tel-input .country-list {
    white-space: normal
  }
}

.intl-tel-input .country-list .divider {
  padding-bottom: 5px;
  margin-bottom: 5px;
  border-bottom: 1px solid #ccc
}

.intl-tel-input .country-list .country {
  padding: 5px 10px;
  display: flex;
}

.intl-tel-input .country-list .country .dial-code {
  color: #999
}

.intl-tel-input .country-list .country.highlight {
  background-color: transparent;
  position: relative;
}

.intl-tel-input .country-list .country-name,
.intl-tel-input .country-list .dial-code,
.intl-tel-input .country-list .flag-box {
  vertical-align: middle
}

.intl-tel-input .country-list .country-name,
.intl-tel-input .country-list .flag-box {
  margin-right: 6px
}

.intl-tel-input.allow-dropdown input,
.intl-tel-input.allow-dropdown input[type=tel],
.intl-tel-input.allow-dropdown input[type=text] {
  padding-right: 6px;
  padding-left: 52px;
  margin-left: 0
}

.intl-tel-input.allow-dropdown .flag-container {
  right: auto;
  left: 0;
  position: unset;
  padding: 0;
  width: auto;
}

.intl-tel-input.allow-dropdown .selected-flag {
  width: 46px
}

.intl-tel-input.allow-dropdown .flag-container:hover {
  cursor: pointer
}

.intl-tel-input.allow-dropdown .flag-container:hover .selected-flag {
  background-color: rgba(0, 0, 0, .05)
}

.intl-tel-input.allow-dropdown input[disabled]+.flag-container:hover,
.intl-tel-input.allow-dropdown input[readonly]+.flag-container:hover {
  cursor: default
}

.intl-tel-input.allow-dropdown input[disabled]+.flag-container:hover .selected-flag,
.intl-tel-input.allow-dropdown input[readonly]+.flag-container:hover .selected-flag {
  background-color: transparent
}

.intl-tel-input.allow-dropdown.separate-dial-code .selected-flag {
  background-color: rgba(0, 0, 0, .05);
  display: table
}

.intl-tel-input.allow-dropdown.separate-dial-code .selected-dial-code {
  display: table-cell;
  vertical-align: middle;
  padding-left: 28px;
  font-size: 15px;
  color: #333;
  padding-left: 0;
}

.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-2 input,
.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-2 input[type=tel],
.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-2 input[type=text] {
  padding-left: 76px
}

.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-2 .selected-flag {
  width: 70px
}

.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-3 input,
.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-3 input[type=tel],
.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-3 input[type=text] {
  padding-left: 84px
}

.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-3 .selected-flag {
  width: 78px
}

.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-4 input,
.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-4 input[type=tel],
.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-4 input[type=text] {
  padding-left: 92px
}

.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-4 .selected-flag {
  width: 86px
}

.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-5 input,
.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-5 input[type=tel],
.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-5 input[type=text] {
  padding-left: 100px
}

.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-5 .selected-flag {
  width: 94px
}

.intl-tel-input.iti-container {
  position: absolute;
  top: -1000px;
  left: -1000px;
  z-index: 1060;
  padding: 1px
}

.intl-tel-input.iti-container:hover {
  cursor: pointer
}

.iti-mobile .intl-tel-input.iti-container {
  top: 30px;
  bottom: 30px;
  left: 30px;
  right: 30px;
  position: fixed
}

.iti-mobile .intl-tel-input .country-list {
  max-height: 100%;
  width: 100%;
  -webkit-overflow-scrolling: touch
}

.iti-mobile .intl-tel-input .country-list .country {
  padding: 10px;
  line-height: 1.5em
}

.iti-flag.ad {
  background-image: url('../../../../public/flags/4x2/ad.png');
}

.iti-flag.ae {
  background-image: url('../../../../public/flags/4x2/ae.png');
}

.iti-flag.af {
  background-image: url('../../../../public/flags/4x2/af.png');
}

.iti-flag.ag {
  background-image: url('../../../../public/flags/4x2/ag.png');
}

.iti-flag.am {
  background-image: url('../../../../public/flags/4x2/am.png');
}

.iti-flag.ao {
  background-image: url('../../../../public/flags/4x2/ao.png');
}

.iti-flag.ar {
  background-image: url('../../../../public/flags/4x2/ar.png');
}

.iti-flag.at {
  background-image: url('../../../../public/flags/4x2/at.png');
}

.iti-flag.au {
  background-image: url('../../../../public/flags/4x2/au.png');
}

.iti-flag.az {
  background-image: url('../../../../public/flags/4x2/az.png');
}

.iti-flag.ba {
  background-image: url('../../../../public/flags/4x2/ba.png');
}

.iti-flag.bb {
  background-image: url('../../../../public/flags/4x2/bb.png');
}

.iti-flag.bd {
  background-image: url('../../../../public/flags/4x2/bd.png');
}

.iti-flag.be {
  background-image: url('../../../../public/flags/4x2/be.png');
}

.iti-flag.bf {
  background-image: url('../../../../public/flags/4x2/bf.png');
}

.iti-flag.bg {
  background-image: url('../../../../public/flags/4x2/bg.png');
}

.iti-flag.bh {
  background-image: url('../../../../public/flags/4x2/bh.png');
}

.iti-flag.bi {
  background-image: url('../../../../public/flags/4x2/bi.png');
}

.iti-flag.bj {
  background-image: url('../../../../public/flags/4x2/bj.png');
}

.iti-flag.bn {
  background-image: url('../../../../public/flags/4x2/bn.png');
}

.iti-flag.bo {
  background-image: url('../../../../public/flags/4x2/bo.png');
}

.iti-flag.br {
  background-image: url('../../../../public/flags/4x2/br.png');
}

.iti-flag.bs {
  background-image: url('../../../../public/flags/4x2/bs.png');
}

.iti-flag.bt {
  background-image: url('../../../../public/flags/4x2/bt.png');
}

.iti-flag.bv {
  background-image: url('../../../../public/flags/4x2/bv.png');
}

.iti-flag.bw {
  background-image: url('../../../../public/flags/4x2/bw.png');
}

.iti-flag.by {
  background-image: url('../../../../public/flags/4x2/by.png');
}

.iti-flag.bz {
  background-image: url('../../../../public/flags/4x2/bz.png');
}

.iti-flag.ca {
  background-image: url('../../../../public/flags/4x2/ca.png');
}

.iti-flag.cd {
  background-image: url('../../../../public/flags/4x2/cd.png');
}

.iti-flag.cf {
  background-image: url('../../../../public/flags/4x2/cf.png');
}

.iti-flag.cg {
  background-image: url('../../../../public/flags/4x2/cg.png');
}

.iti-flag.ch {
  background-image: url('../../../../public/flags/4x2/ch.png');
}

.iti-flag.ci {
  background-image: url('../../../../public/flags/4x2/ci.png');
}

.iti-flag.cl {
  background-image: url('../../../../public/flags/4x2/cl.png');
}

.iti-flag.cm {
  background-image: url('../../../../public/flags/4x2/cm.png');
}

.iti-flag.cn {
  background-image: url('../../../../public/flags/4x2/cn.png');
}

.iti-flag.co {
  background-image: url('../../../../public/flags/4x2/co.png');
}

.iti-flag.cr {
  background-image: url('../../../../public/flags/4x2/cr.png');
}

.iti-flag.cu {
  background-image: url('../../../../public/flags/4x2/cu.png');
}

.iti-flag.cv {
  background-image: url('../../../../public/flags/4x2/cv.png');
}

.iti-flag.cy {
  background-image: url('../../../../public/flags/4x2/cy.png');
}

.iti-flag.cz {
  background-image: url('../../../../public/flags/4x2/cz.png');
}

.iti-flag.de {
  background-image: url('../../../../public/flags/4x2/de.png');
}

.iti-flag.dj {
  background-image: url('../../../../public/flags/4x2/dj.png');
}

.iti-flag.dk {
  background-image: url('../../../../public/flags/4x2/dk.png');
}

.iti-flag.dm {
  background-image: url('../../../../public/flags/4x2/dm.png');
}

.iti-flag.do {
  background-image: url('../../../../public/flags/4x2/do.png');
}

.iti-flag.dz {
  background-image: url('../../../../public/flags/4x2/dz.png');
}

.iti-flag.ec {
  background-image: url('../../../../public/flags/4x2/ec.png');
}

.iti-flag.ee {
  background-image: url('../../../../public/flags/4x2/ee.png');
}

.iti-flag.eg {
  background-image: url('../../../../public/flags/4x2/eg.png');
}

.iti-flag.er {
  background-image: url('../../../../public/flags/4x2/er.png');
}

.iti-flag.es {
  background-image: url('../../../../public/flags/4x2/es.png');
}

.iti-flag.et {
  background-image: url('../../../../public/flags/4x2/et.png');
}

.iti-flag.fi {
  background-image: url('../../../../public/flags/4x2/fi.png');
}

.iti-flag.fj {
  background-image: url('../../../../public/flags/4x2/fj.png');
}


.iti-flag.fm {
  background-image: url('../../../../public/flags/4x2/fm.png');
}

.iti-flag.fr {
  background-image: url('../../../../public/flags/4x2/fr.png');
}

.iti-flag.ga {
  background-image: url('../../../../public/flags/4x2/ga.png');
}

.iti-flag.gb {
  background-image: url('../../../../public/flags/4x2/gb.png');
}

.iti-flag.gd {
  background-image: url('../../../../public/flags/4x2/gd.png');
}

.iti-flag.ge {
  background-image: url('../../../../public/flags/4x2/ge.png');
}

.iti-flag.gh {
  background-image: url('../../../../public/flags/4x2/gh.png');
}

.iti-flag.gm {
  background-image: url('../../../../public/flags/4x2/gm.png');
}

.iti-flag.gn {
  background-image: url('../../../../public/flags/4x2/gn.png');
}

.iti-flag.gq {
  background-image: url('../../../../public/flags/4x2/gq.png');
}

.iti-flag.gr {
  background-image: url('../../../../public/flags/4x2/gr.png');
}

.iti-flag.gt {
  background-image: url('../../../../public/flags/4x2/gt.png');
}

.iti-flag.gw {
  background-image: url('../../../../public/flags/4x2/gw.png');
}

.iti-flag.gy {
  background-image: url('../../../../public/flags/4x2/gy.png');
}

.iti-flag.hk {
  background-image: url('../../../../public/flags/4x2/hongkong.png');
}

.iti-flag.hn {
  background-image: url('../../../../public/flags/4x2/hn.png');
}

.iti-flag.hr {
  background-image: url('../../../../public/flags/4x2/hr.png');
}

.iti-flag.ht {
  background-image: url('../../../../public/flags/4x2/ht.png');
}

.iti-flag.hu {
  background-image: url('../../../../public/flags/4x2/hu.png');
}

.iti-flag.id {
  background-image: url('../../../../public/flags/4x2/id.png');
}

.iti-flag.ie {
  background-image: url('../../../../public/flags/4x2/ie.png');
}

.iti-flag.il {
  background-image: url('../../../../public/flags/4x2/il.png');
}

.iti-flag.in {
  background-image: url('../../../../public/flags/4x2/in.png');
}

.iti-flag.iq {
  background-image: url('../../../../public/flags/4x2/iq.png');
}

.iti-flag.ir {
  background-image: url('../../../../public/flags/4x2/ir.png');
}

.iti-flag.is {
  background-image: url('../../../../public/flags/4x2/is.png');
}

.iti-flag.it {
  background-image: url('../../../../public/flags/4x2/it.png');
}

.iti-flag.jm {
  background-image: url('../../../../public/flags/4x2/jm.png');
}

.iti-flag.jo {
  background-image: url('../../../../public/flags/4x2/jo.png');
}

.iti-flag.jp {
  background-image: url('../../../../public/flags/4x2/jp.png');
}

.iti-flag.ke {
  background-image: url('../../../../public/flags/4x2/ke.png');
}

.iti-flag.kg {
  background-image: url('../../../../public/flags/4x2/kg.png');
}

.iti-flag.kh {
  background-image: url('../../../../public/flags/4x2/kh.png');
}

.iti-flag.ki {
  background-image: url('../../../../public/flags/4x2/ki.png');
}

.iti-flag.km {
  background-image: url('../../../../public/flags/4x2/km.png');
}

.iti-flag.kn {
  background-image: url('../../../../public/flags/4x2/kn.png');
}

.iti-flag.kp {
  background-image: url('../../../../public/flags/4x2/kp.png');
}

.iti-flag.kr {
  background-image: url('../../../../public/flags/4x2/kr.png');
}

.iti-flag.kw {
  background-image: url('../../../../public/flags/4x2/kw.png');
}

.iti-flag.kz {
  background-image: url('../../../../public/flags/4x2/kz.png');
}

.iti-flag.la {
  background-image: url('../../../../public/flags/4x2/la.png');
}

.iti-flag.lb {
  background-image: url('../../../../public/flags/4x2/lb.png');
}

.iti-flag.lc {
  background-image: url('../../../../public/flags/4x2/lc.png');
}

.iti-flag.li {
  background-image: url('../../../../public/flags/4x2/li.png');
}

.iti-flag.lk {
  background-image: url('../../../../public/flags/4x2/lk.png');
}

.iti-flag.lr {
  background-image: url('../../../../public/flags/4x2/lr.png');
}

.iti-flag.ls {
  background-image: url('../../../../public/flags/4x2/ls.png');
}

.iti-flag.lt {
  background-image: url('../../../../public/flags/4x2/lt.png');
}

.iti-flag.lu {
  background-image: url('../../../../public/flags/4x2/lu.png');
}

.iti-flag.lv {
  background-image: url('../../../../public/flags/4x2/lv.png');
}

.iti-flag.ly {
  background-image: url('../../../../public/flags/4x2/ly.png');
}

.iti-flag.ma {
  background-image: url('../../../../public/flags/4x2/ma.png');
}

.iti-flag.mc {
  background-image: url('../../../../public/flags/4x2/mc.png');
}

.iti-flag.md {
  background-image: url('../../../../public/flags/4x2/md.png');
}

.iti-flag.me {
  background-image: url('../../../../public/flags/4x2/me.png');
}

.iti-flag.mg {
  background-image: url('../../../../public/flags/4x2/mg.png');
}

.iti-flag.mh {
  background-image: url('../../../../public/flags/4x2/mh.png');
}

.iti-flag.mk {
  background-image: url('../../../../public/flags/4x2/mk.png');
}

.iti-flag.ml {
  background-image: url('../../../../public/flags/4x2/ml.png');
}

.iti-flag.mm {
  background-image: url('../../../../public/flags/4x2/mm.png');
}

.iti-flag.mn {
  background-image: url('../../../../public/flags/4x2/mn.png');
}

.iti-flag.mo {
  background-image: url('../../../../public/flags/4x2/mo.png');
}

.iti-flag.mr {
  background-image: url('../../../../public/flags/4x2/mr.png');
}

.iti-flag.mt {
  background-image: url('../../../../public/flags/4x2/mt.png');
}

.iti-flag.mu {
  background-image: url('../../../../public/flags/4x2/mu.png');
}

.iti-flag.mv {
  background-image: url('../../../../public/flags/4x2/mv.png');
}

.iti-flag.mw {
  background-image: url('../../../../public/flags/4x2/mw.png');
}

.iti-flag.mx {
  background-image: url('../../../../public/flags/4x2/mx.png');
}

.iti-flag.my {
  background-image: url('../../../../public/flags/4x2/my.png');
}

.iti-flag.mz {
  background-image: url('../../../../public/flags/4x2/mz.png');
}

.iti-flag.na {
  background-image: url('../../../../public/flags/4x2/na.png');
}

.iti-flag.ne {
  background-image: url('../../../../public/flags/4x2/ne.png');
}

.iti-flag.ng {
  background-image: url('../../../../public/flags/4x2/ng.png');
}

.iti-flag.ni {
  background-image: url('../../../../public/flags/4x2/ni.png');
}

.iti-flag.nl {
  background-image: url('../../../../public/flags/4x2/nl.png');
}

.iti-flag.no {
  background-image: url('../../../../public/flags/4x2/no.png');
}

.iti-flag.np {
  background-image: url('../../../../public/flags/4x2/np.png');
  background-color: transparent
}

.iti-flag.nr {
  background-image: url('../../../../public/flags/4x2/nr.png');
}

.iti-flag.nz {
  background-image: url('../../../../public/flags/4x2/nz.png');
}

.iti-flag.om {
  background-image: url('../../../../public/flags/4x2/om.png');
}

.iti-flag.pa {
  background-image: url('../../../../public/flags/4x2/pa.png');
}

.iti-flag.pe {
  background-image: url('../../../../public/flags/4x2/pe.png');
}

.iti-flag.ph {
  background-image: url('../../../../public/flags/4x2/ph.png');
}

.iti-flag.pk {
  background-image: url('../../../../public/flags/4x2/pk.png');
}

.iti-flag.pl {
  background-image: url('../../../../public/flags/4x2/pl.png');
}

.iti-flag.ps {
  background-image: url('../../../../public/flags/4x2/ps.png');
}

.iti-flag.pt {
  background-image: url('../../../../public/flags/4x2/pt.png');
}

.iti-flag.pw {
  background-image: url('../../../../public/flags/4x2/pw.png');
}

.iti-flag.py {
  background-image: url('../../../../public/flags/4x2/py.png');
}

.iti-flag.qa {
  background-image: url('../../../../public/flags/4x2/qa.png');
}

.iti-flag.ro {
  background-image: url('../../../../public/flags/4x2/ro.png');
}

.iti-flag.rs {
  background-image: url('../../../../public/flags/4x2/rs.png');
}

.iti-flag.ru {
  background-image: url('../../../../public/flags/4x2/ru.png');
}

.iti-flag.rw {
  background-image: url('../../../../public/flags/4x2/rw.png');
}

.iti-flag.sa {
  background-image: url('../../../../public/flags/4x2/sa.png');
}

.iti-flag.sb {
  background-image: url('../../../../public/flags/4x2/sb.png');
}

.iti-flag.sc {
  background-image: url('../../../../public/flags/4x2/sc.png');
}

.iti-flag.sd {
  background-image: url('../../../../public/flags/4x2/sd.png');
}

.iti-flag.se {
  background-image: url('../../../../public/flags/4x2/se.png');
}

.iti-flag.sg {
  background-image: url('../../../../public/flags/4x2/sg.png');
}

.iti-flag.sk {
  background-image: url('../../../../public/flags/4x2/sk.png');
}

.iti-flag.sl {
  background-image: url('../../../../public/flags/4x2/sl.png');
}

.iti-flag.sm {
  background-image: url('../../../../public/flags/4x2/sm.png');
}

.iti-flag.sn {
  background-image: url('../../../../public/flags/4x2/sn.png');
}

.iti-flag.so {
  background-image: url('../../../../public/flags/4x2/so.png');
}

.iti-flag.sr {
  background-image: url('../../../../public/flags/4x2/sr.png');
}

.iti-flag.ss {
  background-image: url('../../../../public/flags/4x2/ss.png');
}

.iti-flag.st {
  background-image: url('../../../../public/flags/4x2/st.png');
}

.iti-flag.sv {
  background-image: url('../../../../public/flags/4x2/sv.png');
}

.iti-flag.sy {
  background-image: url('../../../../public/flags/4x2/sy.png');
}

.iti-flag.sz {
  background-image: url('../../../../public/flags/4x2/sz.png');
}

.iti-flag.tg {
  background-image: url('../../../../public/flags/4x2/tg.png');
}

.iti-flag.th {
  background-image: url('../../../../public/flags/4x2/th.png');
}

.iti-flag.tj {
  background-image: url('../../../../public/flags/4x2/tj.png');
}

.iti-flag.tl {
  background-image: url('../../../../public/flags/4x2/tl.png');
}

.iti-flag.tm {
  background-image: url('../../../../public/flags/4x2/tm.png');
}

.iti-flag.tn {
  background-image: url('../../../../public/flags/4x2/tn.png');
}

.iti-flag.to {
  background-image: url('../../../../public/flags/4x2/to.png');
}

.iti-flag.tr {
  background-image: url('../../../../public/flags/4x2/tr.png');
}

.iti-flag.tt {
  background-image: url('../../../../public/flags/4x2/tt.png');
}

.iti-flag.tv {
  background-image: url('../../../../public/flags/4x2/tv.png');
}

.iti-flag.tw {
  background-image: url('../../../../public/flags/4x2/tw.png');
}

.iti-flag.tz {
  background-image: url('../../../../public/flags/4x2/tz.png');
}

.iti-flag.ua {
  background-image: url('../../../../public/flags/4x2/ua.png');
}

.iti-flag.ug {
  background-image: url('../../../../public/flags/4x2/ug.png');
}

.iti-flag.us {
  background-image: url('../../../../public/flags/4x2/us.png');
}

.iti-flag.uy {
  background-image: url('../../../../public/flags/4x2/uy.png');
}

.iti-flag.uz {
  background-image: url('../../../../public/flags/4x2/uz.png');
}

.iti-flag.va {
  background-image: url('../../../../public/flags/4x2/va.png');
}

.iti-flag.vc {
  background-image: url('../../../../public/flags/4x2/vc.png');
}

.iti-flag.ve {
  background-image: url('../../../../public/flags/4x2/ve.png');
}

.iti-flag.vn {
  background-image: url('../../../../public/flags/4x2/vn.png');
}

.iti-flag.vu {
  background-image: url('../../../../public/flags/4x2/vu.png');
}

.iti-flag.ws {
  background-image: url('../../../../public/flags/4x2/ws.png');
}

.iti-flag.xk {
  background-image: url('../../../../public/flags/4x2/xk.png');
}

.iti-flag.ye {
  background-image: url('../../../../public/flags/4x2/ye.png');
}

.iti-flag.za {
  background-image: url('../../../../public/flags/4x2/za.png');
}

.iti-flag.zm {
  background-image: url('../../../../public/flags/4x2/zm.png');
}

.iti-flag.zw {
  background-image: url('../../../../public/flags/4x2/zw.png');
}

.iti-flag {
  width: 34px;
  height: 20px;
  border: solid 1px #cecece;
  background-repeat: no-repeat;
  background-size: 100%;
}

/*
 * Custom CSS
 */
// .iti-flag {
//   background-image: url('../../../../public/flags/flags.png')
// }

.intl-tel-input .country-list::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  -webkit-appearance: none;
}
.intl-tel-input .country-list::-webkit-scrollbar-thumb {
  background-color: #0e730f;
  outline: none;
  border-radius: 0;
}
.intl-tel-input .country-list::-webkit-scrollbar-track {
  background-color: #d7edd7;
}
.intl-tel-input.allow-dropdown.separate-dial-code .selected-flag,
.intl-tel-input.allow-dropdown .flag-container:hover .selected-flag {
  background: transparent;
  outline: 0;
}
.LocationContacts .intl-tel-input.allow-dropdown.separate-dial-code .selected-dial-code {
  font-size: 13px;
}
.intl-tel-input .selected-flag:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 1px;
  border-right: 1px solid #cecece;
  right: 0;
}
.intl-tel-input .selected-flag:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.intl-tel-input .country-list {
  left: 0;
  right: 0;
  border-radius: 6px;
  margin-top: 5px;
}
.flag-overlay-circle {
  margin-left: 10px;
  display: flex;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.flag-overlay-circle .flag-icon {
  width: 100%;
  border: 1px solid #cecece;
  border-radius: 50%;
}
.intl-tel-input .country-list .country-name {
  align-items: center;
  justify-content: flex-start;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 5px;
  max-width: 50%;
}
.intl-tel-input .country-list .country-name,
.intl-tel-input .country-list .country .dial-code {
  font-size: 13px;
  color: #444;
  line-height: 20px;
}
.intl-tel-input .country-list .loadmore {
  font-size: 13px;
  color: var(--color-green);
  line-height: 20px;
}
.intl-tel-input .country-list .country.highlight:before,
[data-selected-country="true"]:before {
  position: absolute;
  top: 8px;
  right: 7px;
  width: 18px;
  height: 13px;
  background-size: 100%;
}
.intl-tel-input .country-list .country.highlight,
.intl-tel-input .country-list .country.highlight .country-name,
.intl-tel-input .country-list .country.highlight .dial-code,
.intl-tel-input .country-list .country[data-selected-country="true"] .country-name,
.intl-tel-input .country-list .country[data-selected-country="true"] .dial-code {
  color: var(--color-green);
}
ul.country-list {
  display: flex;
  flex-wrap: wrap;
  li {
    width: 100%;
  }
  .divider {
    width: 100% !important;
    height: unset;
    position: unset;
    border-left: unset;
  }
}
.load-all-country.divider {
  border-bottom: 0 none !important;
}
[data-selected-country="true"]:before {
  content: '';
  background-image: url('../../../../public/flags/check-flag-highlight.svg');
}