.select-payment-container {
  background: #f1f1f1;
  margin: 10px 0;
  .header {
    font-size: 14px;
    color: #282828;
    padding: 15px;
    padding-bottom: 0px;
  }
  .bank-item-section {
    padding: 0 15px;
    .bank-item-border-bottom {
      display: flex;
      border-bottom: 1px solid #dbdbdb;
      padding-bottom: 10px;
    }
    .bank-item, .bank-item-border-bottom {
      display: flex;
      margin-top: 17px;
      padding-bottom: 10px;
      .bank-border-bottom {
        border-bottom: 1px solid #dbdbdb;
      }
      .bank-info {
        color: #282828;
        padding-left: 10px;
        display: flex;
        flex: 1;
        .bank-name-logo {
          position: relative;
          margin: 2px 0;
          .bank-name {
            font-size: 14px;
          }
          .selected-bank {
            font-weight: bold;
          }
          .bank-content {
            font-size: 12px;
            padding-top: 5px;
            .replace-fee {
              font-weight: bold;
            }
          }
        }
      }
      .bank-logo {
        position: relative;
        right: 0;
        top: -3px;
        img {
          height: 20px;
        }
      }
    }
    .checkbox-circle {
      position: relative;
      display: block;
      user-select: none;
      height: 20px;
      width: 20px;
      cursor: pointer;
      input {
        position: absolute;
        display: none;
        &:checked ~ .checkmark {
          border-color: #3fae29;
          &::before {
            background-color: #3fae29;
          }
        }
      }
    }
    .checkbox-circle-ceb {
      position: relative;
      display: block;
      user-select: none;
      height: 20px;
      width: 20px;
      pointer-events: none;
      input {
        position: absolute;
        display: none;
        &:checked ~ .checkmark {
          border-color: #a1a1a1;
          pointer-events: none;
          &::before {
            background-color: #a1a1a1;
          }
        }
      }
    }
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      background-color: var(--color-white);
      border-radius: 50%;
      border: 1px solid var(--color-border-gray);
      box-sizing: border-box;
  
      &:before {
        content: '';
        display: block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        top: 4px;
        left: 4px;
        position: absolute;
      }
    }
  }
}

.none-select {
  pointer-events: none;
}