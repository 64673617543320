.deletion-account {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 380px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 11111;
  color: #282828;

  .deletion-account-box {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    z-index: 10001;
    background-color: var(--color-white);

    .header {
      width: 100%;
      min-height: 60px;
      align-items: center;
      display: flex;
      padding: 10px 20px;
      box-sizing: border-box;
      background: var(--color-green);
      >div {
        font-size: 19px;
        line-height: 22px;
        font-weight: 700;
        color: #fff;
        width: 100%;
        text-align: center;
      }
    }

    .content {
      flex: 1;
      padding: 0 17px 17px 17px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 8px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #0e730f;
        outline: none;
        border-radius: 0;
      }
      &::-webkit-scrollbar-track {
        background-color: #d7edd7;
      }
      .title {
        text-align: center;
        font-size: 22px;
        font-weight: 500;
        margin: 20px 0
      }
      .sub-title {
        font-size: 14px;
        margin-bottom: 17px;
        text-align: center;
      }
      .reason-list {
        margin-top: 20px;
        font-size: 16px;
        .reason {
          display: flex;
          span {
            margin-right: 10px;
          }
          margin-bottom: 20px;
          &:last-child {
            margin-bottom: unset;
          }
        }
      }
      .notify {
        padding: 13px 10px;
        border: 1px solid #DBDBDB;
        border-radius: 3px;
        margin-top: 17px;
        box-sizing: border-box;
        resize: none;
        width: 100%;
        font-size: 16px;
      }
    }
    .footer {
      text-align: center;
      padding: 10px;
      box-shadow: 0 0 10px 0 rgb(0 0 0 / 80%);
      .gray.Button {
        margin-right: 10px;
      }
      .Button {
        width: calc(50% - 10px);
        &.disabled.red {
          color: #282828 !important;
          background-color: #F04433 !important;
          opacity: 0.3;
        }
      }
    }
  }
  .policy-line {
    font-size: 14px;
    display: flex;
    margin-bottom: 10px;
    div:first-child {
      margin-right: 10px;
      width: 10px;
    }
    div:last-child {
      flex: 1;
      white-space: pre-line;
    }
  }
  .policy-accept {
    display: flex;
    margin-top: 30px;
    >div:first-child {
      margin-right: 10px;
    }
  }
  .button-policy {
    font-size: 12px;
    padding: 5px 10px;
    background: #F9F9F9;
    border-radius: 3px;
    border: 1px #A1A1A1 solid;
    cursor: pointer;
    &:hover {
      color: var(--color-green);
    }
  }
  .step-3-enter-password {
    .Input {
      height: 44px;
      border-radius: 4px;
    }
  }
  .step4-content {
    padding: 0;
    opacity: 0.8;
    display: flex;
    background-color: black;
  }
  .step-4-account-deleted {
    background-color: white;
    width: 256px;
    padding: 15px;
    border-radius: 12px;
    text-align: center;
    font-size: 14px;
    margin: auto;
  }

  .notify-error {
    padding: 15px;
    font-size: 14px;
    opacity: 0.8;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
}

.popup-warning.Modal.visible {
  position: absolute;
  .Normal-Booking {
    width: calc(100% - 40px);
  }
  .Normal-Booking .Box-Content {
    padding: 0 20px;
  }
}
.step-3-enter-password {
  .Input.Custom.password-error {
    border: 1px solid #f44336;
    background-color: rgb(244 67 54 / 10%);
  }
  i.xb.material-icons.Icon.green {
    color: var(--color-green);
  }
  
  i.xb.material-icons.Icon {
    color: var(--color-gray);
  }
}
#root ~ #fc_frame.fc-mobile-view.h-open-container {
  bottom: 0 !important;
}
@media (max-width: 768px) {
  .deletion-account {
    width: 100%;
  }
}