.saver-booking-compare-container {
  position: relative;
  width: 100%;
  min-height: 33px;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: #EBF6E9;
  }
  .saver-booking-compare-text {
    color: #282828;
    padding-left: 10px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .saver-i-icon-border {
    min-width: 33px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0px 20px 20px 0px;
    border-top: 1px solid #3FAE29;
    border-right: 1px solid #3FAE29;
    border-bottom: 1px solid #3FAE29;
    .saver-i-icon {
      height: 14px;
    }
  }
}

#popover-saver-booking {
  .popover-wrapper {
    border-radius: 6px;
    .popover-anchor {
      height: 40px;
      width: 40px;
    }
    .saver-booking-content-container {
      position: relative;
      width: 306px;
      padding: 15px 10px;
      .saver-close-icon {
        left: -12px !important;
        top: -12px !important;
      }
      .saver-title {
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      .saver-subtitle {
        color: #FFF;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 18.2px;
      }
      .saver-standard {
        color: #FFF;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .saver-link {
        color: #44E952;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration-line: underline;
        cursor: pointer;
      }
    }
  }
}