.Driver-Preferences-Container {
  .explain-text {
    font-size: var(--font-size);
    color: var(--color-background-gray);
    margin: 0;
    padding: 0 0 10px 10px;
  }

  .Popover-Item-Driver-Item {
    padding-top: 0;
  }

  .Popover-Item-Driver-Item-Avatar span {
    border-radius: 50%;
    &.avatar-fleet {
      background-size: 48px 48px;
      background-position: center 1px;
    }
  }

  .Popover-Item-Driver-Item-Actions .Button {
    background-color: var(--color-gray-xxxl);
    color: var(--color-black);
    border: 1px solid var(--color-border-gray);
    width: 72px;
    height: 24px;
    font-size: 12px;
    border-radius: 3px;
  }

  .Driver-Preferences-Items {
    height: 44px;
    border-right: 1px solid #d8d8d8;
    border-top: 1px solid #d8d8d8;
    border-bottom: 1px solid #d8d8d8;
    box-sizing: border-box;

    &:first-child {
      border-radius: var(--radius) 0 0 var(--radius);
      border-left: 1px solid #d8d8d8;
    }

    &:last-child {
      border-radius: 0 var(--radius) var(--radius) 0;
    }
  }

  .active {
    background-color: var(--color-dark-green);
    border-right: 1px solid var(--color-dark-green);
    border-top: 1px solid var(--color-dark-green);
    border-bottom: 1px solid var(--color-dark-green);

    &:first-child {
      border-left: 1px solid var(--color-dark-green);
    }
  }
}

.favorite-driver-text {
  color: var(--color-background-gray);
  font-size: 15px;
  text-align: center;
  padding-bottom: 10px;

  span {
    color: var(--color-green);
    cursor: pointer;
    text-transform: lowercase;
  }

  img {
    width: 16px;
    margin-right: 5px;
  }
}

.Popover-Item-Drivers {

  &.Multiple-Booking,
  &.Single {

    &:before {
      z-index: 10;
    }

    .Popover-Item-Driver-Group {
      background: var(--color-white);
      padding: 0;
      height: 100%;
      display: flex;
      flex-direction: column;

      .question-icon {
        margin-left: 10px;
        cursor: pointer;
      }

      .group-btn {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 15px 0;
        background-color: var(--color-white);
        border-radius: 0 0 10px 10px;

        p {
          font-size: var(--font-size);
          color: var(--color-background-gray);
        }
      }

      .driver-list-wrapper {
        margin-top: 10px;
        flex: 1;
        position: relative;

        .loading-more {
          width: 20px;
          height: 20px;
          position: absolute;
          bottom: 10px;
          left: 50%;
          transform: translateX(-50%);
        }
      }

      .driver-row {

        &:last-child {
          position: relative;
          padding-bottom: 40px;
        }

        .title {
          background-color: #f1f1f1;
          margin: 0;
          padding: 10px 15px;
          font-size: 14px;
          color: #282828;
        }
      }
    }

    .Popover-Item-Driver-Search {
      padding: 0 10px;
      margin-top: 10px;
    }

    .Input {
      padding: 0 10px;

      &:focus-within {
        border-color: var(--color-green);
      }

      input {
        padding: 0 0 0 10px;
      }
    }

    .Popover-Item-Driver-Item-Avatar span {
      border-radius: 50%;
      &.avatar-fleet {
        background-size: 48px 48px;
        background-position: center 1px;
      }
    }

    .Popover-Item-Driver-Item-Info {

      .Popover-Item-Driver-Name-Text {
        font-size: var(--font-size-b);
      }

      p {
        font-size: 14px;
      }
    }

    .Popover-Item-Driver-Item-Actions {
      .Button {
        background-color: var(--color-gray-xxxl);
        border-color: var(--color-border-gray);
        font-size: 12px;
        width: 72px;
        height: 24px;
        border-radius: var(--radius-s);
      }

      .Selected {
        background-color: #40ae29;
        border: solid 1px #02751a;
        color: var(--color-white);
      }
    }

    .empty-driver {
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 72%;

      .empty-driver-title {
        color: #282828;
        font-size: 19px;
        font-weight: 500;
        margin: 10px 0;
      }

      .empty-driver-content {
        font-size: 15px;
        color: #a1a1a1;
        margin: 0;
      }

      .Icon {
        color: #0e730f;
        font-size: var(--foint-size);
        margin: 0;
      }

      img {
        transform: translateX(-20%);
      }
    }

    .requirement-popup-wrapper {
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      text-align: center;
      background: rgba(0,0,0, 0.8);
      display: none;
      z-index: 10;

      &.visible {
        display: block;
      }

      .requirement-popup {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 320px;
        height: 238px;

        .Box {
          height: 100%;
          align-items: center;
        }

        .content-wrapper {
          border-radius: 16px;
          padding: 20px 10px;
          box-sizing: border-box;
          background: var(--color-green);
          color: var(--color-white);
        }

        .title {
          color: var(--color-yellow);
          font-size: 20px;
          font-weight: 500;
          margin: 25px 0 10px;
        }

        .content {
          font-size: 16px;
          margin: 0;
        }

        .explain-content {
          background-color: rgba(255, 255, 255, 0.56);
          border-radius: 5px;
          padding: 10px;
          color: var(--color-background-red);
          font-size: 15px;
          font-weight: 500;
          margin-top: 20px;
        }

        .back-btn {
          background: var(--color-white);
          color: var(--color-green);
          font-size: 16px;
          font-weight: 500;
          margin-top: 10px;
          border-radius: 5px;
          border: none;
          padding: 10px;
          width: 100%;
        }

        .icon-popup {
          display: flex;
          align-items: center;
          background-color: var(--color-white);
          width: 64px;
          height: 64px;
          position: absolute;
          transform: translateY(-50%);
          border-radius: 50%;
          overflow: hidden;
          box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.5);

          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }

    .Popover-Item-Driver-Item {
      border-radius: unset;
      padding: 16px 16px;
      margin: 0;
      border: 1px solid rgba(0, 0, 0, 0.1);

      .invalid-driver-text {
        color: var(--color-background-red);
        font-size: 14px;
        font-weight: 500;
        width: 88px;
        text-align: center;
      }

      &.error {
        border: 1px solid rgba(0, 0, 0, 0.1);
      }
    }

    .invalid-driver {
      background-color: #f1f1f1;
      cursor: pointer;
    }

    .BoxCustom-Close {
      top: -22px;
      right: -22px;
    }

    .Popover-Item-Driver-Item-Middle {
      .pickup-datetime {
        border: 1px solid var(--color-light-gray);
        border-radius: 4px;
        margin: 0 auto;
        width: 320px;
      }

      .select-available-text {
        font-size: 14px;
        color: #282828;
        width: 320px;
        margin: 10px auto;

        .Popover-Item-Driver-Item-Icon-Eye {
          width: 14px;
          cursor: pointer;
          display: inline-block;
          position: relative;
          top: 2px;

          img {
            width: 100%;
          }
        }
      }
    }

    .Popover-Item-Driver-Item-Bottom {
      margin: 0 -10px;

      .slot-time {
        border: 1px solid #a1a1a1;
        border-radius: 3px;
        margin-right: 8px;

        &.selected {
          background-color: #40ae29;
          border: 1px solid #02751a;
        }
      }

      .Driver-Item-Arrow {
        color: var(--color-green);

        &.disabled {
          color: var(--color-light-gray);
        }
      }
    }

    .unvailable-driver {
      border: 1px solid rgba(0, 0, 0, 0.1);
      padding: 0 10px 16px;
      background-color: rgba(240, 68, 51, 0.1);

      .Popover-Item-Driver-Item {
        padding: 16px 0 0;
        border: none;
      }

      .PickupTime-DatePicker {
        width: auto;
        left: 0;
      }

      .unvailable-desc {
        color: var(--color-background-red);
        font-weight: 500;
      }
    }

    .Modal-Change-Data-Time .Modal {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      &.visible {
        overflow-y: unset;
      }

      .Popover-Item-Driver-Item-Icon-Eye {
        margin: 0;
      }

      .Popup-Booking-Custom {
        min-width: auto;
      }
    }
  }

  &.Single {
    height: 85%;
    padding: 10px;
    top: 5%;
    width: 365px;

    .Popover-Item {
      padding: 0;
    }

    .Popover-Item-Driver-Item-Bottom {
      .slot-time {
        padding: 10px 0;
        text-align: center;
        min-width: 64px;
      }
    }

    .Popover-Arrow {
      width: 20px;
      height: 20px;
      left: -4px;
    }
  }

  &.Multiple-Booking {
    padding: 10px;

    .Popover-Item {
      padding: 0;
    }

    .driver-list-wrapper {
      overflow-x: hidden;
    }

    .unvailable-driver {
      .invalid-driver-text {
        margin-right: -15px;
      }

      .Popover-Item-Driver-Item-Avatar {
        padding: 0;
      }

      .pickup-datetime {
        width: auto;
      }
    }
  }

  &.hide-overlay:before {
    background: none;
  }
}

.MultipleBookingLayout .BookingWizard .Multiple-Booking {
  width: 365px;
  margin-left: 0;
  left: 0;

  &:before {
    content: none;
  }
}

#popup-recents-drivers {

  &.visible {
    background-color: unset;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0, 0.8);
  }
}

.Preference-Driver-Icon {
  button {
    margin-left: 10px;
  }
}