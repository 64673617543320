.BatchUploadAssign .step-2:before {
  content: none
}

.SmartPlanner {
  height: calc(100vh - 60px);
  background-color: var(--color-gray-xxxl);

  .ContactInfo {
    width: 593px;
    margin: 10px 0 0;
    padding: 15px;
    border-radius: 5px;
    border: solid 1px #d8d8d8;
    background-color: #f1f1f1;
    font-size: 16px;
    color: rgba(40, 40, 40, 0.85);
  }

  .pickup-time {
    &.pickup-detail-section {
      align-items: center;

      img {
        margin-top: 0;
      }
    }

    &.empty-contact {
      height: auto;
    }
  }

  .divide {
    width: 530px;
    height: 1px;
    margin: 10px 0 10px 30px;
    background-color: #d8d8d8;
  }

  .empty-contact {
    font-style: italic;
    height: 59px;
    cursor: pointer;
  }

  .List-Item .Button {
    width: 191px;
    height: 36px;
    border-radius: 4px;
    border: solid 1px #51af2b;
    background-color: var(--color-white);
    margin: 0;
  }

  .pickup-buttons-wrapper {
    margin-top: 10px;
  }

  .dropdown-wrapper {
    margin-right: 10px;
  }

  .PickupTime-DatePicker-Custom {
    width: auto;
    top: 0;
  }

  .Tabs-Content-Right-List {
    font-weight: 500;
    line-height: 1.67;
    color: #4d4d4d;
  }

  .PageHead-title {
    padding-right: 12px;
  }

  .step-2 {
    &:before {
      content: none
    }
  }

  .Pin + label {
    font-size: 16px;
    font-weight: 500;
  }

  .batch_upload_form {
    margin-bottom: 0;

    .batch_upload_form_list,
    .batch_upload_form_dropzone {
      width: 180px;
      height: 105px;
    }

    .batch_upload_form_list {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border: 1px solid #d8d8d8;
      border-radius: 5px;
      background-color: #f1f1f1;

      .Button {
        top: -10px;
        right: -10px;
        border-color: #047201;

        &:hover {
          background-color: #047201;
        }
      }

      .filename {
        color: var(--color-gray-d);
        font-size: 13px;
        margin-top: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 160px;
      }
    }

    .error-upload {
      background-color: rgba(240, 68, 51, 0.1);
      border: 1px solid var(--color-border-red);

      .filename {
        color: var(--color-border-red);
      }
    }

  }

  .error-upload-message {
    color: var(--color-border-red);
    font-size: var(--font-size);
    margin-left: 34px;
    font-weight: 400;

    img {
      width: 15px;
      margin-right: 10px;
      vertical-align: text-top;
    }
  }

  .Modal-Basic .continue-btn {
    min-width: 120px;
    width: auto;
    max-width: unset;
  }

  .message-popup {
    position: unset;
    text-align: center;
    transform: none;
    margin-bottom: 80px;
    font-weight: 500;
  }

  .Default-Dropdown .Default-Dropdown-List {
    padding: 10px;
  }

  .BatchUploadBook-actions.actions {
    height: 63px;
    padding: 14px 20px;

    .btn-save-draft {
      border: solid 1px var(--color-border-gray);
      width: 137px;
      font-size: 16px;
    }

    .btn-next {
      width: 170px;
      height: 36px;
      margin-left: 10px;
      font-size: 16px;

      &.disabled {
        opacity: 0.4;
        background-color: var(--color-green);
        color: #282828;
        border: none;
        border-radius: var(--radius);
        cursor: not-allowed;
      }
    }
  }

  .FormGroup-Upload-Batch-Name .Input,
  .FormGroup-Upload-Batch-Name .TextArea {
    max-width: 593px !important;
  }

  .outside-error {
    margin: 0 0 10px 0;
  }

  .Overlay-Checkbox {
    &.Green-Checkbox {
      input[type=checkbox]:checked+label:before {
        background-color: var(--color-green);
        border-color: var(--color-border-green);
      }

      label {
        position: relative;

        .minus-icon {
          background-color: white;
          width: 10px;
          height: 2px;
          display: block;
          position: absolute;
          left: 3px;
          top: 7px;
        }
      }
    }

    &.Gray-Checkbox.Disable label:before {
      border-color: #ddd;
    }

    &.Disable input[type=checkbox]:checked+label:before {
      background-color: #ddd;
      border-color: #ddd;
    }
  }

  .Steps .Step.selected {
    font-weight: bold;
  }

  .Button.gray {
    color: #282828;
  }

  .Default-Dropdown-Title {
    font-weight: 500;
  }

  .BatchUpload-Title {
    padding: 5px 0 0;
  }

  .location-dot-pickup {
    background-color: var(--color-blue);

    &:after {
      border-color: var(--color-blue);
    }
  }

  .popup-wrapper {
    z-index: 100;

    .popup-header {
      padding: 15px;
      text-align: center;
      background-color: var(--color-background-green);
      color: var(--color-white);
      text-transform: capitalize;
      font-size: 17px;
      font-weight: bold;
    }

    .popup-inner {
      width: 600px;
      background-color: var(--color-white);
      border-radius: 6px;
      overflow: hidden;
    }

    .popup-content {
      padding: 20px 14px;
      max-height: 500px;
      overflow-y: auto;

      .invalid-row {
        padding: 16px 10px;
        border: 1px solid var(--color-gray-xl);
        display: flex;
        justify-content: space-between;
        font-size: 15px;
        color: #282828;

        &:first-child {
          border-top-left-radius: 6px;
          border-top-right-radius: 6px;
        }

        &:last-child {
          border-bottom-left-radius: 6px;
          border-bottom-right-radius: 6px;
        }

        .ignore-checkbox {
          display: flex;
          font-size: 16px;

          p {
            margin: 0 0 0 10px;
          }
        }
      }
    }

    .btn-wrapper {
      padding-right: 10px;
      justify-content: flex-end;
      margin: 0 0 10px;
      font-size: 18px;

      .button {
        width: 120px;
      }

      .ignore-all {
        color: var(--color-gray-xxd);
        border: 1px solid var(--color-gray-d);
        background-color: var(--color-white);
      }

      .confirm {
        color: var(--color-white);
        background-color: var(--color-background-green);
        margin-left: 10px;
      }
    }

    .Overlay-Checkbox {
      width: 20px;
    }
  }

  .tooltip {
    height: 18px;
    margin-left: 10px;
    cursor: pointer;

    &:before {
      width: 200px;
      background: #282828;
      padding: 10px 14px;
      line-height: normal;
      box-sizing: border-box;
    }

    img {
      width: 18px;
      height: 18px;
    }
  }

  #Step-01,
  #Step-03 {
    [data-tooltip]:hover:before,
    [data-tooltip]:hover:after {
      display: block;
      z-index: 10001;
    }
  }

  .pickup-time-mode {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .List-Item {
      margin: 0;
    }
  }

  .pickup-detail-section {
    display: flex;
    align-items: flex-start;

    img {
      margin-top: 5px;
      margin-right: 10px;
      width: 20px;
    }
  }

  #Step-01 {
    background-color: var(--color-white);
  }

  .pagehead-title-wrapper {
    display: flex;
    flex: 1;
  }
}

button.toast-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
}

.smart-loading {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(#40ae29, #02751a);
  z-index: 2147483601;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-percent {
  position: relative;
  width: 64px;
  height: 64px;
  border-radius: 50%;

  .ring {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.2);
    animation: pulse 4s infinite;
  }

  .ring-2 {
    animation-delay: 1.5s;
  }

  .ring-3 {
    animation-delay: 2.5s;
  }

  .ring-4 {
    animation-delay: 3.5s;
  }

  .percent {
    font-size: var(--font-size-b);
    font-weight: bold;
    color: var(--color-white);
    width: 100%;
    height: 100%;
    line-height: 64px;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 50%;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1, 1);
  }

  100% {
    transform: scale(5.75, 5.75);
    opacity: 0
  }
}

.loading-description {
  text-align: center;
  color: var(--color-white);
  position: absolute;
  top: 75%;

  p {
    margin: 0;
  }

  .title {
    font-size: var(--font-size-b);
    margin-bottom: 5px;
  }

  .desc {
    font-size: var(--font-size);
    font-weight: 100;
    line-height: 20px;
  }
}

.invalid-data {
  border: 1px solid var(--color-gray-xl);
  border-radius: 6px;
  margin-left: 34px;
  width: 593px;
  margin-top: 10px;

  .invalid-header {
    background-color: rgba(240, 68, 51, 0.1);
    text-transform: uppercase;
    color: var(--color-red);
    font-size: 16px;
    font-weight: 500;
    padding: 15px 10px;
  }

  .invalid-row {
    padding: 15px 10px;
    font-weight: 500;
    color: var(--color-gray-xxd);
    border-bottom: 1px solid var(--color-gray-xl);
    display: flex;
    justify-content: space-between;
    background-color: var(--color-white);

    &:last-child {
      border: none;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    .ignore-checkbox {
      display: flex;

      p {
        margin: 0 0 0 10px;
      }
    }
  }

  .show-more {
    padding: 10px;
    text-align: center;
    font-weight: 500;
    color: var(--color-border-green);
    background-color: #f1f1f1;
    cursor: pointer;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

.ignored-row {
  text-decoration: line-through;
  color: var(--color-gray-xl);
}

@media only screen and (max-height: 470px) {
  .SmartPlanner .message-popup {
    margin-bottom: 45px;
  }
}

.ez-multiple-chat-hotline #root~#fc_frame.custom-frame {
  bottom: 146px !important;
}

@media (min-width: 1024px) and (max-width: 1366px) {
  .ez-multiple-chat-hotline #root~#fc_frame.custom-frame {
    bottom: 120px !important;
  }
}