.StatusBar {
  position: absolute;
  top: var(--menu-height);
  right: 0;
  left: 0;

  display: flex;
  align-items: center;
  background-color: var(--color-white);
  height: 50px;
  border-bottom: 1px solid #d4d4d4;
  box-sizing: border-box;

  .title {
    display: flex;
    align-items: center;
    padding: 0 60px 0 20px;
    font-size: 24px;
    font-weight: bold;
    color: #444444;
    box-sizing: border-box;
  }

  .Button {
    min-width: 120px;
  }

  .back-icon {
    box-sizing: border-box;
    height: 36px;
    width: 36px;
    min-width: 36px;
    padding: 0;

    &:hover {
      use {
        fill: var(--color-white);
      }
    }
  }

  .Button:hover,
  .Button:focus {
    background: var(--color-green);
    color: var(--color-white);
  }

  .radius-15 {
    border-radius: 15px;
  }

  .height-28 {
    height: 28px !important;
    max-height: 28px !important;
  }

  .badge-status {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: var(--color-white);
    margin-left: 16px;
    padding: 0px 8px;
    text-transform: uppercase;
    font-weight: 400;
    box-sizing: border-box;
  }

  .default-badge {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    margin-left: 16px;
    padding: 0px 8px;
    text-transform: capitalize;
    font-weight: 400;
    border: 1px solid var(--color-green);
    color: var(--color-black);
    background-color: #EBF6E8;
    box-sizing: border-box;
  }

  .download-wrapper {
    position: relative;
    display: inline-block;
  }

  .download-modal {
    display: block;
    min-width: 180px;
    padding: 10px;
  }

  .flex-column {
    flex-direction: column;
  }

  .cancel-btn {
    min-width: 120px;
    height: 36px;
    border: 1px solid var(--color-border-red);
    border-radius: 5px;
    color: var(--color-border-red);
    transition: all 0.1s ease-in;
    background-color: transparent;
    padding: 0 10px;

    &:hover {
      color: var(--color-white);
      background-color: var(--color-border-red)
    }
  }
}

.detail-status-bar .StatusBar {
  top: auto;
  right: auto;
  left: auto;
  width: 100%;
}
